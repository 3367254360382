exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nutzungsbedingungen-jsx": () => import("./../../../src/pages/nutzungsbedingungen.jsx" /* webpackChunkName: "component---src-pages-nutzungsbedingungen-jsx" */),
  "component---src-pages-pflanzen-index-jsx": () => import("./../../../src/pages/pflanzen/index.jsx" /* webpackChunkName: "component---src-pages-pflanzen-index-jsx" */),
  "component---src-pages-schaedlinge-und-krankheiten-index-jsx": () => import("./../../../src/pages/schaedlinge-und-krankheiten/index.jsx" /* webpackChunkName: "component---src-pages-schaedlinge-und-krankheiten-index-jsx" */),
  "component---src-pages-schaedlinge-und-krankheiten-pest-data-url-jsx": () => import("./../../../src/pages/schaedlinge-und-krankheiten/{PestData.url}.jsx" /* webpackChunkName: "component---src-pages-schaedlinge-und-krankheiten-pest-data-url-jsx" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-plant-family-js": () => import("./../../../src/templates/plant-family.js" /* webpackChunkName: "component---src-templates-plant-family-js" */),
  "component---src-templates-plant-js": () => import("./../../../src/templates/plant.js" /* webpackChunkName: "component---src-templates-plant-js" */)
}

